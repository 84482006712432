:root {
  --primary: #009e7f;
  --light-primary: #00e6b8;
  --dark-primary: #008f72;

  --shadow: #ccc;

  --secondary: #a3a3a3;

  --success: #089e2b;
  --green: #089e2b;
  --warning: #ffef4a;
  --yellow: #ffef4a;
  --light: #f8f9fa;
  --white: #f8f9fa;

  --danger: #9e101c;
  --red: #9e101c;

  --blue: #086b9e;

  --dark: #343a40;
  --black: #343a40;
  --transition-speed: 600ms;
}

* {
  box-sizing: border-box;
}
body,
html {
  overflow-x: hidden;
}

body {
  font-family: "Libre Caslon Text", serif;
  font-size: 18px;
  color: black;
  background-color: white;
  position: relative;
  margin: 0;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: var(--dark-primary);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
