/** NavBar  */
.header {
  background-color: var(--primary);
}

.list_cursos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1em;
}
.list_cursos_item {
  padding: 1em;
  background-color: var(--primary);
  border-radius: 0.5em;
  color: white;
  cursor: pointer;
}

/** NavBarItems */
.nav_header {
  z-index: 10;
  margin: 0;
  padding: 0;
  height: 100%;
  justify-content: space-between;
  padding: 0 1em;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.nav_list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  list-style: none;
  align-items: center;
}

/** NavBarItem */
.nav_list_items {
  margin: 0.5em;
  height: 2.375em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_list_items_item {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav_list_items_item:hover,
.nav_list_items_item:focus,
.nav_list_items_item___active {
  color: var(--light-primary);
}

/** DropdownItem */
.dropdown {
  width: 100%;
  height: 100%;
}
.dropdown .dropbtn {
  width: 100%;
  height: 2.375em;
  font-size: 1em;
  border: none;
  outline: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  /* Important for vertical align on mobile phones */
}

.content {
  height: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  z-index: 1;
  transition: opacity 0.5s linear, height 0.5s linear;
}
.content___open {
  height: auto;
  visibility: visible;
  padding: 0.5em;
  margin-bottom: 1em;
  opacity: 1;
  border-radius: 1em;
}

/** SideDrawer */
.SideDrawer {
  position: absolute;
  width: 100%;
  z-index: 3;
  background-color: var(--dark-primary);
  box-sizing: border-box;

  visibility: hidden;
  height: 0;
  opacity: 0;
}

.SideDrawer___Open {
  padding: 0.5em;
  height: auto;
  transition: all 0.5s ease-in-out;
  visibility: visible;
  opacity: 1;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_img {
  display: grid;
  place-content: center;
}

.menu {
  display: block;
  color: white;
  font-size: 2em;
  display: flex;
  justify-content: center;
}
.content > .nav_list_items {
  height: auto;
  gap: 1em;
  margin-top: 0;
}

/** Media Queries */
@media (min-width: 600px) {
  /** NavBarItems */

  .nav {
    width: 100%;
  }
  .nav_list {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
  }

  /** NavBarItem */
  .nav_list_items {
    width: fit-content;
    height: 3.375em;
    margin: 0;
    margin-top: 1em;
  }

  .nav_list_items_item {
    padding: 0.5em;
    padding-bottom: 1.5em;
  }

  .nav_list_items_item:hover,
  .nav_list_items_item:focus,
  .nav_list_items_item___active {
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    background: white;
    color: black;
  }

  /** DropdownItem */
  .dropdown {
    width: fit-content;
    height: 100%;
  }
  .dropdown .dropbtn {
    height: 3.375em;
    cursor: pointer;
  }

  .content {
    position: absolute;
    width: fit-content;
    max-width: 250px;
    padding: 1em;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    background: var(--dark-primary);
  }

  .content > .nav_list_items > .nav_list_items_item {
    padding: 0;
    border-bottom: 1px solid transparent;
  }

  .content > .nav_list_items > .nav_list_items_item:hover,
  .content > .nav_list_items > .nav_list_items_item:focus,
  .content > .nav_list_items > .nav_list_items_item___active {
    border-radius: 0;
    background: transparent;
    color: var(--light-primary);
    border-bottom: 1px solid var(--light-primary);
    cursor: pointer;
  }
}
