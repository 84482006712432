/** Input */
.Input {
  width: 100%;
  min-width: 5em;
  padding: 0.5em;
  font-size: 1em;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid var(--dark);
  line-height: normal;
}

.Input___error {
  border: 1px solid var(--red);
}

.Input___inline {
  display: flex;
  align-items: center;
  gap: 0.375em;
}

.Input___textarea {
  min-height: 56px;
  resize: none;
}
.Input___textarea:focus {
  outline: none;
  border: 1px solid var(--primary);
}

.Input:focus {
  outline: none;
  border: 1px solid var(--primary);
}

.Input_label {
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 1em;
}

/** InputWButtons*/
.InputWButtons {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  margin: 0;
}

.InputWButtons_pre,
.InputWButtons_post {
  border-radius: none;
  outline: none;
  border: none;
  text-align: center;
  border: 1px solid #ccc;
  padding: 0;
  padding: 0.25em 0.5em;
  cursor: pointer;
  line-height: 1em;
}

.InputWButtons_pre {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.InputWButtons_post {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.InputWButtons_input {
  width: 25px;
  text-align: center;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.InputWSelect {
  border: 1px solid var(--shadow);
  border-radius: 0.5em;
  display: grid;
  grid-template-columns: 5fr 1fr;
}
.InputWSelect input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  padding: 0.5em;
  margin: 0;
  border: none;
  border-radius: 0.5em;
}

.InputWSelect select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding: 0.5em;
  margin: 0;
  border: none;
  border-left: 1px solid #ccc;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

.input_group {
  display: grid;
  grid-template-columns: 100px 1fr 40px;
}

.input_group_label {
  border: 1px solid #ccc;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  padding: 0 1em;
  margin: 0;
  display: grid;
  place-content: center;
}
.input_group_radio {
  border: 1px solid #ccc;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  line-height: 100px;
}
.input_group_textarea {
  outline: none;
  border: 1px solid #ccc;
  resize: none;
  padding: 0.5em 0.725em;
}
