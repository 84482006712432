/** Modal  */
.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.Modal {
  z-index: 500;
  background-color: white;
  width: 70%;
  max-height: 80%;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 1px 1px 1px black;
  transition: all 0.3s ease-out;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
}
.Modal___fullsize {
  width: 100%;
  height: 100%;
  max-width: 95%;
  max-height: 95%;
}

.Modal_header {
  margin: 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}

.Modal_header___error {
  background-color: var(--red);
  color: white;
}

.Modal_content {
  padding: 1em;
  overflow-y: auto;
}

.Modal_content___centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Modal_footer {
  border-top: 1px solid var(--shadow);
  padding: 0.5em;
}
